import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import HighlightSeries from "../components/common/highlightsSeries"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Img from 'gatsby-image'
import Link from '../utilities/link'
import SlideInRight from '../components/utility/slideInRightAnimation'
import SvgHeraLaundry from "../illustrations/home/heraLaundry"
import Modal from "../components/common/modal"
import LaundryNews from "../components/forms/laundryNewsForm"
import ExitIntent from "../utilities/exitIntent"

const HeraMarketingHome = ({ data }) => {

  const { t } = useTranslation();
  const [elcIsVisible, setShowElc] = useState(1);
  const [caIsVisible, setShowCa] = useState(0);
  const [paIsVisible, setShowPa] = useState(0);
  const [activeQuoteIndex, setActiveQuoteIndex] = useState(0);

  function showElc() {
    setShowElc(1);
    setShowCa(0);
    setShowPa(0);
  }

  function showCa() {
    setShowElc(0);
    setShowCa(1);
    setShowPa(0);
  }

  function showPa() {
    setShowElc(0);
    setShowCa(0);
    setShowPa(1);
  }

  function updateIndex(currentRef) {
    setActiveQuoteIndex(t('referenze', { returnObjects: true }).findIndex((element, index) => {
      if (element.name === currentRef.name) {
        return true
      }
    }));
  }

  // gestione popup modal

  const [showModal, setShowModal] = useState(false);

  // caricamento dopo 10 secondi

  useEffect(() => {
    // dopo 10 secondi dal caricamento della pagina, compare il popup
    const timer = setTimeout(() => setShowModal(true), 10000);
    //poi il timer si resetta
    return () => clearTimeout(timer);
  }, []);

  // caricamento on exit (con cookie per evitare che si ripresenti il popup fino al giorno successivo)

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 30,
      eventThrottle: 100,
      onExitIntent: () => {
        if (document.cookie.indexOf("modal_seen=true") < 0) {
          setShowModal(true)
          let expiryDate = new Date(
            Date.now() + 1 * (1000 * 60 * 60 * 24)
          )
          expiryDate.setFullYear(expiryDate.getFullYear() + 1)
          document.cookie =
            "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
          }
      },
    })
    return () => {
      removeExitIntent()
    }
  })


  return (

  <Layout>
    { showModal 
      ? 
      <Modal
        isActive = "is-active"
        component={<LaundryNews />}
        onCloseClick={() => setShowModal(false)}
      /> 
      : 
      null
    }
    <Seo
      title={t("home.seoTitle")}
      description={t("home.seoDescription")}
      seoImage={data.cpu_hera.childImageSharp.resize.src}
    />
    {/* Sezione Header */}
    <div className="section is-medium is-relative has-light-background">
    <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
      <defs>
      <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
      <stop stop-color="#7dd1a7" offset="0"/>
      <stop stop-color="#71bdf4" offset="1"/>
      </linearGradient>
      </defs>
      <g id="Livello 1" layerName="Livello 1">
      <path d="M-1.43513+0.575764L2002.67+0.575764L2000+381.632C2000+381.632+1827.32+319.668+1447.66+379.973C1068.01+440.279+830.684+620.543+474.162+617.13C109.78+613.64-2.95224+456.261-2.95224+456.261L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
      </g>
    </svg>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h1 className="title is-white is-bottom-bordered-white">{t('home.pageTitle')}</h1>
              <h2 className="title is-4" style={{marginTop: 0, maxWidth: '450px'}}>{t('home.pageSubtitle')}</h2>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <SvgHeraLaundry />
          </div>
        </div>
      </div>
    </div>

    {/* Sezione Overview Sistema Hera */}
    <div className="section is-medium has-light-background">
       <div className="container">
         <div className="content is-large has-text-centered">
           <h2 className="title is-2">{t('home.overviewTitle')}</h2>
           <p className="subtitle is-3" style={{paddingTop: '20px'}}>{t('home.overviewSubtitle')}</p>
         </div>
       <div className="section">
         <div className="columns has-text-centered">
           <div className="column is-4">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showElc()}><strong>{t('home.overviewButton1')}</strong></div>
           </div>
           <div className="column is-4">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showCa()}><strong>{t('home.overviewButton2')}</strong></div>
           </div>
           <div className="column is-4">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showPa()}><strong>{t('home.overviewButton3')}</strong></div>
           </div>
         </div>
          <div className={`${elcIsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('home.overviewControllerTitle')}</h2>
              </div>
              <div className="content">            
                <p>{t('home.overviewControllerText')}</p>
                  <ul>
                    <li>{t('home.overviewControllerLi1')}</li>
                    <li>{t('home.overviewControllerLi2')}</li>
                    <li>{t('home.overviewControllerLi3')}</li>
                  </ul>
              </div>
              <Link to="/lavanderie-industriali/hera-smart" title={t('header.HeraSmartTitleTag')} className="button is-medium is-outlined is-primary" style={{marginTop: '25px'}}><strong>{t('home.overviewControllerButton')}</strong></Link>
            </div>
            <div className="column is-6">
              <Img fluid={data.cpu_hera.childImageSharp.fluid} alt={t('home.altImageCPU')}/>
            </div>
          </div>
          <div className={`${caIsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('home.overviewWebAppTitle')}</h2>
                </div>
              <div className="content">
                <p>{t('home.overviewWebAppText1')}</p>
                <ul>
                  <li><strong>{t('home.overviewWebAppLi1')}</strong></li>
                  <li><strong>{t('home.overviewWebAppLi2')}</strong></li>
                  <li><strong>{t('home.overviewWebAppLi3')}</strong></li>
                  <li><strong>{t('home.overviewWebAppLi4')}</strong></li>
                </ul>
                <p>{t('home.overviewWebAppText2')}
                </p>
                </div>
                <Link className="button is-medium is-outlined is-primary" to="/smart-dashboard" title={t('header.HeraSmartDashboardTitleTag')} style={{marginTop: '25px'}}><strong>{t('home.overviewWebAppButton')}</strong></Link>
            </div>
            <div className="column is-6">
              <Img fluid={data.hera_web.childImageSharp.fluid} alt={t('home.altImageWebApp')}/>
            </div>
          </div>
          <div className={`${paIsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('home.overviewMobileAppTitle')}</h2>
                </div>
                <p>{t('home.overviewMobileAppText')}<strong>{t('home.overviewMobileAppTextBold')}</strong>
                </p>
              <Link to="/configuratore-lavaggi" title={t('header.HeraWashConfiguratorTitleTag')} className="button is-medium is-outlined is-primary" style={{marginTop: '25px'}}><strong>{t('home.overviewMobileAppButton')}</strong></Link>
            </div>
            <div className="column is-6">
              <Img fluid={data.mobile_app.childImageSharp.fluid} alt={t('home.altImageMobile')}/>
            </div>
          </div>
        </div>
      </div>
     </div>

    {/* Sezione Mission */}
      <HighlightSeries
        //illustration={<SvgHeraLaundry />}
        //fluid={data.textile_laundry.childImageSharp.fluid}
        mainTitle={t('home.missionTitle')}
        isGradient="is-soft-gradient"
        title={t('home.missionSubtitle')}
        mainOne={t('home.missionHighlight')}
      />
    {/* Sezione Benefici */}
    <div className="section is-medium">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-5">
            <div className="content is-large is-bottom-bordered">
              <h2 className="title is-1">
                {t('home.benefitsTitle')}
              </h2>
            </div>
            <div className="content">
              <p className="is-subtitle">
              {t('home.benefitsSubtitle')}
              </p>
            </div>
            <Img fluid={data.laundry_dashboard.childImageSharp.fluid} alt={t('home.altImageDashboard')}/>
          </div>
          <div className="column is-1"></div>
          <div className="column is-6">
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px', borderBottom: '3px solid #7dd1a7'}}>
              <h3 className="title is-4">{t('home.benefit1Title')}</h3>
              <p>{t('home.benefit1Text')}</p>
            </div>
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px', borderBottom: '3px solid #7dd1a7'}}>
              <h3 className="title is-4">{t('home.benefit2Title')}</h3>
              <p>{t('home.benefit2Text')}</p>
            </div>
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px', borderBottom: '3px solid #7dd1a7'}}>
              <h3 className="title is-4">{t('home.benefit3Title')}</h3>
              <p>{t('home.benefit3Text')}</p>
            </div>
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px'}}>
              <h3 className="title is-4">{t('home.benefit4Title')}</h3>
              <p>{t('home.benefit4Text')}</p>
            </div>
          </div>
          <div className="column is-6 has-text-right" style={{paddingTop: '75px'}}>
            <Link to="/lavanderie-industriali/hera-smart/" title={t('header.HeraSmartTitleTag')} className="button is-medium is-outlined is-info"><strong>{t('home.benefitsButton1')}</strong></Link>
          </div>
          <div className="column is-6 has-text-left" style={{paddingTop: '75px'}}>
            <Link to="/smart-dashboard/" title={t('header.HeraSmartDashBoardTitleTag')} className="button is-medium is-outlined is-primary"><strong>{t('home.benefitsButton2')}</strong></Link>
          </div>
        </div>
      </div>
    </div>
     
     {/* Sezione testimonial e referenze */}
     <div className="section is-medium has-light-background">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <div className="content">
              <h2 className="title is-2 is-bottom-bordered">{t('home.testimonialTitle')}</h2>
              <p>{t('home.testimonialSubtitle')}</p>
            </div>
          </div>
          <div className="column is-1"></div>
          <div className="column is-7">
          {t('referenze', { returnObjects: true }).map((ref) => activeQuoteIndex === t('referenze', { returnObjects: true }).findIndex((element, index) => {
            if (element.name === ref.name) {
              return true
            }
          }) ?
            <SlideInRight>
            <div className={`content`} key={ref.name}>
              <h4 className="title is-3 is-bottom-bordered">{ref.name}</h4>
              <p className="subtitle is-5">{ref.quote}</p>
            </div>
            </SlideInRight>
            : ""
          )}
          <div className="container is-flex is-centered" style={{paddingTop: '75px'}}>
          {t('referenze', { returnObjects: true }).map((ref) => activeQuoteIndex === t('referenze', { returnObjects: true }).findIndex((element, index) => {
            if (element.name === ref.name) {
              return true
            }
          }) ?
        <span onClick={() => updateIndex(ref)}>
         <svg xmlns="http://www.w3.org/2000/svg" style={{width: '50px', height: '50px'}} fill="none" viewBox="0 0 24 24" stroke={`#2196f3`}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
        </svg>
        </span>
        :
        <span onClick={() => updateIndex(ref)}>
         <svg xmlns="http://www.w3.org/2000/svg" style={{width: '50px', height: '50px'}} fill="none" viewBox="0 0 24 24" stroke={`currentColor`}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
         </svg>
        </span>
        
        )}
          </div>
          </div>
        </div>
          
      </div>
    </div>
  </Layout>
  )
}

export default HeraMarketingHome

export const Home = graphql`
  query {
    laundry_dashboard: file(
      relativePath: { eq: "LaundryDashboard.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    app: file(
      relativePath: { eq: "orderConfirmed.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cpu_hera: file(
      relativePath: { eq: "Hera_Controllo_Elettronico_Per_Lavatrici.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    hera_web: file(
      relativePath: { eq: "Hera_Smart_Dashboard_Detail_View.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobile_app: file(
      relativePath: { eq: "Hera_laundry_mobile_app.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
